import React, { useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { Heading, Video } from '@bhvr/web-design-system'
import { StaticImage } from 'gatsby-plugin-image'

import AnimatedBackground from '@base/elements/animated-background'
import useWindowSize from '@base/utils/hooks/useWindowSize'
import Button from '@base/parts/button'
import Markdown from '@base/parts/markdown'

const GameplayBlock = ({ data, pageContext }) => {
  const windowSize = useWindowSize()
  const overviewsRef = useRef(null)
  const [currentVideoPlaying, setCurrentVideoPlaying] = useState([0, false]) // video index (int.) and pause state (bool)

  const [refLoad, inViewLoad] = useInView({
    threshold: 0,
    triggerOnce: true,
  })

  const [refTitle, inViewTitle] = useInView({
    threshold: 0.5,
  })

  let autoplay = true
  if (windowSize.width <= 1024) {
    autoplay = false
  }

  useEffect(() => {
    if (windowSize.width <= 1024) {
      autoplay = false
    }
  }, [windowSize])

  useEffect(() => {
    // Autoplay First video on page load
    if (autoplay) {
      overviewsRef.current.childNodes.forEach((overview) => {
        let dataIndex = overview.getAttribute('data-index')
        let overviewVideo = overview.querySelector('video')
        if (parseInt(dataIndex) === 0) {
          overviewVideo.play()
        }
      })
    }
  }, [])

  function playVideo(index) {
    setCurrentVideoPlaying([index, false])
    overviewsRef?.current?.childNodes?.forEach((overview) => {
      let dataIndex = overview.getAttribute('data-index')
      let overviewPlayBtn = overview.querySelector('.video-overview-play')
      let overviewVideo = overview.querySelector('video')

      if (parseInt(dataIndex) === parseInt(index)) {
        if (overviewPlayBtn.classList.contains('playing')) {
          // Pause Video
          setCurrentVideoPlaying([index, true])
          overviewVideo.pause()
        } else {
          // Play video
          setCurrentVideoPlaying([index, false])
          overviewVideo.play()
        }
      } else {
        // Pause every other videos
        overviewVideo.pause()
      }
    })
  }

  return (
    <section className={`gameplay-section relative pb-20`}>
      {data.block?.anchorID && (
        <span
          id={data.block?.anchorID}
          className="gameplay-section-anchor absolute -top-80"
        />
      )}
      <div
        ref={refLoad}
        className="relative z-1 flex flex-col items-center justify-center text-center"
      >
        <div
          ref={refTitle}
          style={{
            opacity: inViewTitle ? 1 : 0,
            transition: 'all 0.5s',
          }}
          className="flex w-full flex-col items-center justify-center"
        >
          {data.title && (
            <Heading
              use={data.title.tag}
              className={`gameplay-title text-lg text-goldDark md:text-xl lg:text-xxxl ${data.description ? 'mb-8' : 'mb-16'
                }`}
            >
              <Markdown children={data.title.text} />
            </Heading>
          )}
        </div>
        <div
          ref={overviewsRef}
          className="flex w-full flex-col items-stretch justify-center space-y-16 px-10 lg:w-[85%] lg:flex-row lg:space-x-10 lg:space-y-0 lg:px-0 xl:w-3/4 xl:space-x-16 xxl:w-[65%]"
        >
          {data.videoOverviews?.map((video, i) => (
            <div
              className="gameplay-overview relative w-full bg-white lg:w-1/3"
              key={`video-overview-${i}`}
              data-index={i}
            >
              <div className="relative">
                <Button
                  id={`gameplayVideoBtn-${i}`}
                  trigger={'clickAction'}
                  key={`gameplayVideoBtn-${i}`}
                  pageContext={pageContext}
                  overwrite
                  className={`video-overview-play absolute left-0 top-0 z-10 h-full w-full cursor-pointer ${currentVideoPlaying[0] === i &&
                      currentVideoPlaying[1] === false
                      ? 'playing'
                      : 'paused'
                    }`}
                  onClick={() => playVideo(i)}
                  trackable={true}
                  event={`play_gameplay_pillar_${i + 1}`}
                  eventSection={'home gameplay'}
                >
                  <div className="video-overview-play-btn">
                    <i className="video-play-icon" />
                  </div>
                </Button>
                <Video
                  autoplay={false}
                  loading="lazy"
                  loop={true}
                  muted={true}
                  player="self"
                  className="video-overview"
                  source={video?.video?.url}
                  poster={video?.videoPoster?.url}
                />
              </div>
              <div className="px-12 py-12 lg:pt-6">
                <h3 className="font-sans text-[2.5rem] leading-none tracking-wider lg:text-xl">
                  {video.title}
                </h3>
                <div className="separator-dual-small mb-12 mt-12 lg:mt-6">
                  <div className="line" />
                  <div className="diamond" />
                  <div className="line" />
                </div>
                <Markdown
                  className="font-display text-base font-[300] leading-normal"
                  children={video.text?.data?.text}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="gatsby-hidden-mobile opacity-50">
        {/* Right Islands */}
        <AnimatedBackground
          className={`background-content-island gatsby-absolute -right-[1rem] top-[85%]`}
        >
          <div className="-scale-x-100">
            <StaticImage
              src="../../assets/img/islands/island_bottom_left.png"
              alt=""
              className={`background-element-small w-[4rem] lg:w-[5rem]`}
            />
          </div>
        </AnimatedBackground>
        <AnimatedBackground
          className={`background-content-island gatsby-absolute right-0 top-1/3 lg:-right-12`}
          translate={[100, 0]}
        >
          <StaticImage
            src="../../assets/img/islands/island_center.png"
            alt=""
            className={`background-element-large w-[6rem] md:w-[10rem] lg:w-[18rem]`}
          />
        </AnimatedBackground>

        {/* Left Island */}
        <AnimatedBackground
          className={`background-content-island gatsby-absolute -left-[3rem] top-1/2`}
        >
          <div className="-scale-x-100">
            <StaticImage
              src="../../assets/img/islands/island_bottom_left.png"
              alt=""
              className={`background-element-small w-[5rem] md:w-[7rem] lg:w-[10rem]`}
            />
          </div>
        </AnimatedBackground>
      </div>
    </section>
  )
}

export default GameplayBlock
