import React from 'react'
import { motion } from 'framer-motion'
import { StaticImage } from 'gatsby-plugin-image'
import SVG from 'react-inlinesvg'

import AnimatedBackground from '@base/elements/animated-background'

import bgIcon from '@img/bg.svg'

const PageBackground = (props) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, delay: 0.5 }}
      className={`background-wrapper pointer-events-none absolute left-0 top-0 z-under flex h-full w-full items-center justify-center overflow-hidden ${props.aboveFold ? 'bg-auto' : 'bg-cover'} ${props.className ? props.className : ''}`}
    >
      <div className="background-content relative w-full h-full">
        {/* Background */}
        <SVG
          onError={(error) => console.log(error.message)}
          cacheRequests={true}
          src={bgIcon}
          className={`gatsby-absolute left-1/2 h-auto w-[150%] lg:w-full -translate-x-1/2 ${props.aboveFold ? 'top-0' : 'top-4 lg:-top-36'}`}
          keepStyle
        />

        {/* Top Left Islands*/}
        <AnimatedBackground className="background-content-island gatsby-absolute top-8 left-12 lg:left-48 xxxl:left-96">
          <StaticImage
            src="../../assets/img/islands/island_top_left_tiny.png"
            alt=""
            className={`background-element-small hidden md:block w-[6rem] md:w-[8rem] lg:w-auto`}
          />
        </AnimatedBackground>
        <AnimatedBackground
          className={`background-content-island gatsby-absolute ${props.aboveFold ? '-top-5 md:top-0 lg:-top-32 -left-14 md:-left-48 xxxl:left-0' : '-top-16 md:-top-40 lg:-top-60 xxxl:-top-40 -left-28 md:-left-48 xxxl:left-0'}`}
          translate={[100, 0]}
        >
          <StaticImage
            src="../../assets/img/islands/island_top_left.png"
            alt=""
            className="background-element-large w-[15rem] md:w-[32rem] lg:w-auto"
          />
        </AnimatedBackground>

        {/* Top Right Islands*/}
        <AnimatedBackground
          className={`background-content-island gatsby-absolute ${props.aboveFold ? 'top-40 md:top-40 lg:top-96 xxxl:top-[30rem] right-10 md:right-14 lg:right-16' : 'top-56 md:top-48 lg:top-80 xxxl:top-[31rem] right-10 md:right-20 lg:right-16'}`}
        >
          <StaticImage
            src="../../assets/img/islands/island_top_right_tiny.png"
            alt=""
            className={`background-element-small w-[5rem] md:w-[7rem] lg:w-[15rem] xxxl:w-auto`}
          />
        </AnimatedBackground>
        <AnimatedBackground
          className={`background-content-island gatsby-absolute ${props.aboveFold ? 'top-6 md:-top-28 lg:-top-48 right-0 lg:-right-12' : '-top-48 md:-top-60 lg:-top-72 xxxl:-top-48 right-0 lg:-right-12'}`}
          translate={[100, 0]}
        >
          <StaticImage
            src="../../assets/img/islands/island_top_right.png"
            alt=""
            className={`background-element-large w-[6rem] md:w-[10rem] lg:w-auto`}
          />
        </AnimatedBackground>

        {/* Bottom Islands*/}
        <AnimatedBackground
          className={`background-content-island gatsby-absolute gatsby-hidden-mobile -left-16 ${props.aboveFold ? '-bottom-32' : 'bottom-[32rem]'}`}
          translate={[300, 0]}
        >
          <StaticImage
            src="../../assets/img/islands/island_bottom_left.png"
            alt=""
            className="background-element-large"
          />
        </AnimatedBackground>
        <AnimatedBackground
          className={`background-content-island gatsby-absolute gatsby-hidden-mobile -right-24 xxxl:right-0 ${props.aboveFold ? '-bottom-48' : 'bottom-[32rem]'}`}
          translate={[200, 0]}
        >
          <StaticImage
            src="../../assets/img/islands/island_bottom_right.png"
            alt=""
            className="background-element-small"
          />
        </AnimatedBackground>
      </div>
    </motion.div>
  )
}

export default PageBackground
