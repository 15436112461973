import React from 'react'
import { useInView } from 'react-intersection-observer'
import { Heading } from '@bhvr/web-design-system'
import { StaticImage } from 'gatsby-plugin-image'

import AnimatedBackground from '@base/elements/animated-background'
import Markdown from '@base/parts/markdown'

import CardsSlider from '../elements/cards-slider'

const CardsBlock = ({ data, globalData, pageContext }) => {
  const [refLoad, inViewLoad] = useInView({
    threshold: 0,
    triggerOnce: true,
  })

  const [refTitle, inViewTitle] = useInView({
    threshold: 0.5,
  })

  const [refDesc, inViewDesc] = useInView({
    threshold: 0.5,
  })

  return (
    <section className={`cards-section relative pb-20 lg:pb-40`}>
      {data.block?.anchorID && (
        <span
          id={data.block?.anchorID}
          className="cards-section-anchor absolute -top-80"
        />
      )}
      <div
        ref={refLoad}
        className="flex flex-col items-center justify-center text-center"
      >
        <div className="flex w-[65%] flex-col items-center justify-center text-center md:w-full">
          <div
            ref={refTitle}
            style={{
              opacity: inViewTitle ? 1 : 0,
              transition: 'all 0.5s',
            }}
            className="flex w-full flex-col items-center justify-center"
          >
            {data.title && (
              <Heading
                use={data.title.tag}
                className={`cards-title text-lg text-goldDark md:text-xl lg:text-xxxl ${
                  data.description ? 'mb-8' : 'mb-16'
                }`}
              >
                <Markdown children={data.title.text} />
              </Heading>
            )}
          </div>
          {data.description && (
            <div
              ref={refDesc}
              style={{
                opacity: inViewDesc ? 1 : 0,
                transition: 'all 0.5s',
              }}
              className="mb-12 flex w-full flex-col items-center justify-center md:w-2/3 lg:mb-16 lg:w-1/2 xl:w-[40%]"
            >
              <Markdown
                children={data.description.text?.data?.text}
                className="font-display text-base font-[300] leading-snug lg:text-md"
              />
            </div>
          )}
        </div>
        <div className="relative flex w-full flex-col flex-wrap items-stretch justify-center px-24 lg:w-auto lg:flex-row lg:px-0">
          <CardsSlider cards={data.cards} strings={globalData.stringsAll} pageContext={pageContext} />
        </div>
      </div>
      <div className="gatsby-hidden-mobile opacity-50">
        {/* Left Islands */}
        <AnimatedBackground
          className={`background-content-island gatsby-absolute -top-[15%] left-0 lg:-left-32`}
          translate={[100, 0]}
        >
          <StaticImage
            src="../../assets/img/islands/island_center.png"
            alt=""
            className={`background-element-large w-[6rem] md:w-[10rem] lg:w-[24rem]`}
          />
        </AnimatedBackground>
        <AnimatedBackground
          className={`background-content-island gatsby-absolute left-[7rem] top-[25%]`}
        >
          <div className="-scale-x-100">
            <StaticImage
              src="../../assets/img/islands/island_bottom_left.png"
              alt=""
              className={`background-element-small w-[4rem] lg:w-[8rem]`}
            />
          </div>
        </AnimatedBackground>
        <AnimatedBackground
          className={`background-content-island gatsby-absolute -left-[9rem] top-[70%]`}
        >
          <div className="-scale-x-100">
            <StaticImage
              src="../../assets/img/islands/island_top_right_tiny.png"
              alt=""
              className={`background-element-small w-[4rem] lg:w-[18rem]`}
            />
          </div>
        </AnimatedBackground>

        {/* Right Islands */}
        <AnimatedBackground
          className={`background-content-island gatsby-absolute right-[4rem] top-[45%]`}
        >
          <StaticImage
            src="../../assets/img/islands/island_top_right_tiny.png"
            alt=""
            className={`background-element-small w-[4rem] lg:w-[15rem]`}
          />
        </AnimatedBackground>
        <AnimatedBackground
          className={`background-content-island gatsby-absolute -right-[10rem] top-[2%]`}
          translate={[100, 0]}
        >
          <StaticImage
            src="../../assets/img/islands/island_bottom_left.png"
            alt=""
            className={`background-element-small w-[4rem] lg:w-[28rem]`}
          />
        </AnimatedBackground>
        <AnimatedBackground
          className={`background-content-island gatsby-absolute gatsby-hidden-mobile -bottom-[30rem] right-0`}
          translate={[100, 0]}
        >
          <StaticImage
            src="../../assets/img/islands/island_center_bottom.png"
            alt=""
            className="background-element-small"
          />
        </AnimatedBackground>
      </div>
    </section>
  )
}

export default CardsBlock
