/*
  PAGE TEMPLATE
  A template to display fully editable pages
*/

import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { motion } from 'framer-motion'

import PostContextProvider from '@base/utils/contexts/posts-context'
import triggerPageView from '@base/utils/helpers/tracking/trigger-page-view'
import PageLayout from '@base/layout/page-layout'
import Blocks from '@base/layout/blocks'

import { getGeneralData } from '@api/general-data'

const DynamicPage = ({ data, pageContext }) => {
  const generalData = getGeneralData(pageContext, data)
  const minifiedPageContext = generalData.pageContext
  const globalData = generalData.globalData
  const modalData = generalData.modalData
  const { title, parentPage, disablePageBreadcrumbs } = data.pageData
  const contentSections = data.blocksData.contentSections
  const notificationBanner = pageContext.globalData?.global?.edges[0].node.notificationBanner.enabled

  // Data Layer - Page Views
  useEffect(() => {
    triggerPageView(minifiedPageContext)
  }, [])

  return (
    <PageLayout
      pageContext={minifiedPageContext}
      modalData={modalData}
      breadcrumbs={{
        enableGlobalBreadcrumbs: true,
        disablePageBreadcrumbs: disablePageBreadcrumbs,
        postTitle: title,
        postParent: [parentPage],
        strings: {
          back: globalData.stringsActions.back,
          home: globalData.stringsSlugs.homeTitle,
        },
      }}
    >
      <PostContextProvider>
        <Blocks
          pageContext={{
            ...minifiedPageContext,
            notificationBanner: notificationBanner,
          }}
          globalData={globalData}
          blocks={contentSections}
        />
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ delay: 0.5, duration: 0.5 }}
          className="newsletter-section-background"
        />
      </PostContextProvider>
    </PageLayout>
  )
}
export default DynamicPage

export const query = graphql`
  query DynamicPageQuery($id: String!) {
    pageData: strapiPage(id: { eq: $id }) {
      title
      slug
      locale
      disablePageBreadcrumbs
      disableNewsletterSignup
      disableBuyGameSection
      parentPage {
        slug
        title
      }
      metadata {
        metaTitle
        metaDescription
        canonicalURL
        disablePageIndexing
        socialMediaShare {
          socialNetwork
          title
          image {
            localFile {
              publicURL
            }
          }
        }
      }
      localizations {
        data {
          attributes {
            locale
            slug
            title
            publishedAt
          }
        }
      }
    }
    blocksData: strapiPage(id: { eq: $id }) {
      contentSections {
        ...HeroBlock
        ...TogglesBlock
        ...VideoBlock
        ...Gallery
        ...CardsBlock
        ...HeadingBlock
        ...GameplayBlock
        ...FormBlock
      }
    }
  }
`
export { Head } from '@base/layout/head'
