import React, { useRef, useState } from 'react'
import SVG from 'react-inlinesvg'
import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectCoverflow, Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/effect-coverflow'

import trackLink from '@base/utils/helpers/tracking/track-link'
import Image from '@base/parts/image'
import Markdown from '@base/parts/markdown'

import arrow from '@img/icons/arrow.svg'

const CardsSlider = ({ cards, strings, pageContext }) => {
  const prevRef = useRef(null)
  const nextRef = useRef(null)
  const animationWrapper = useRef(null)
  const [openedCard, setOpenedCard] = useState(null)
  let slidesPerView = 1

  // Slide Counter
  function updSwiperNumericPagination(realIndex) {
    const slideAmount = cards.length
    const counter = document.querySelector('.slider-counter')

    if (counter && slideAmount) {
      document.querySelector('.slider-counter').innerHTML =
        '<span class="count">' +
        (realIndex + 1) +
        '</span>/<span class="total">' +
        slideAmount +
        '</span>'
    }
  }
  // Flip the card on click
  const openCard = (target, index) => {
    const cardDataLayer = {
      event: `puzzle_category_${index + 1}`,
      eventSection: 'home puzzles',
      pageContext: pageContext,
    }
    if (openedCard === target) {
      setOpenedCard(null)
    } else {
      setOpenedCard(target)
      trackLink(cardDataLayer)
    }
  }

  return (
    <>
      <Swiper
        ref={animationWrapper}
        className={'w-full'}
        slidesPerView={slidesPerView}
        effect={'coverflow'}
        grabCursor={true}
        spaceBetween={20}
        centeredSlides={true}
        loop={true}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 0,
          modifier: 1,
          slideShadows: false,
        }}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        onInit={(swiper) => {
          const { realIndex } = swiper
          swiper.params.navigation.prevEl = prevRef.current
          swiper.params.navigation.nextEl = nextRef.current
          swiper.navigation.init()
          swiper.navigation.update()
          updSwiperNumericPagination(realIndex)
        }}
        onSlideChange={(swiper) => {
          const { realIndex } = swiper
          updSwiperNumericPagination(realIndex)
        }}
        modules={[EffectCoverflow, Navigation]}
      >
        {cards.map((card, i) => (
          <SwiperSlide
            key={`card-${i}`}
            onClick={() => openCard(`card-${i}`, i)}
            className={`card relative w-full text-goldLight lg:w-1/3 ${openedCard === `card-${i}` ? 'opened' : 'closed'
              }`}
          >
            <div className="card-content">
              <div className="card-front flex flex-col items-center justify-between px-8 py-10">
                <h3 className="font-sans text-lg uppercase leading-none tracking-wider">
                  {card.title}
                </h3>
                <Image
                  media={card.icon}
                  className="card-icon h-60 w-60"
                  keepStyle={true}
                />
                <p className="flex w-full justify-end font-display text-sm">
                  {strings?.cta?.seeMore}
                  <SVG
                    onError={(error) => console.log(error.message)}
                    cacheRequests={true}
                    preProcessor={(code) =>
                      code.replace(/fill=".*?"/g, 'fill="currentColor"')
                    }
                    src={arrow}
                    className="ml-2 h-[1.4rem] w-auto -rotate-90 text-goldLight"
                  />
                </p>
                <div className="card-borders">
                  <div className="card-border card-border-left" />
                  <div className="card-border card-border-right" />
                </div>
              </div>
              <div className="card-back flex flex-col items-center justify-center px-12 py-10">
                <h3 className="mb-8 font-sans text-lg uppercase leading-none tracking-wider">
                  {card.title}
                </h3>
                <Markdown
                  className="font-display text-base leading-normal text-white font-light"
                  children={card.text?.data?.text}
                />
                <div className="card-borders">
                  <div className="card-border card-border-left" />
                  <div className="card-border card-border-right" />
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
        <div className="slider-counter mt-4 flex justify-center font-sans text-lg font-medium lg:hidden" />
      </Swiper>
      {cards.length > 1 && (
        <nav className="slider-navigation flex lg:hidden">
          <button
            ref={prevRef}
            className="slider-navigation-button slider-navigation-prev"
          >
            <SVG
              onError={(error) => console.log(error.message)}
              cacheRequests={true}
              preProcessor={(code) =>
                code.replace(/fill=".*?"/g, 'fill="currentColor"')
              }
              src={arrow}
              className="mr-4 h-8 w-auto rotate-90 text-goldLight"
            />
          </button>
          <button
            ref={nextRef}
            className="slider-navigation-button slider-navigation-next"
          >
            <SVG
              onError={(error) => console.log(error.message)}
              cacheRequests={true}
              preProcessor={(code) =>
                code.replace(/fill=".*?"/g, 'fill="currentColor"')
              }
              src={arrow}
              className="ml-4 h-8 w-auto -rotate-90 text-goldLight"
            />
          </button>
        </nav>
      )}
    </>
  )
}

export default CardsSlider
