import React from 'react'
import { motion } from 'framer-motion'
import { Heading, Accordion, AccordionRow } from '@bhvr/web-design-system'
import Markdown from '@base/parts/markdown'

const TogglesBlock = ({ data, index }) => {
  return (
    <section className="toggles-section relative z-0 overflow-hidden py-[4.4rem] lg:py-32 lg:min-h-[80rem]">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="relative z-10 box-border flex w-full flex-col items-center justify-center text-center text-white lg:container"
      >
        {data.title && data.title !== null && (
          <Heading
            use={data.title.tag}
            className="mb-12 text-goldDark lg:mb-20"
          >
            {data.title.text}
          </Heading>
        )}

        {data.subTitle && data.subTitle !== null && (
          <Heading
            use={data.subTitle.tag}
            className="mb-12 tracking-wider text-goldDark lg:mb-20"
          >
            <Markdown children={data.subTitle.text} />
          </Heading>
        )}

        {data.toggle && (
          <div className="relative mx-auto w-full lg:bg-secondary lg:max-w-[90rem]">
            <Accordion
              single
              className="w-full border-none border lg:border-solid border-primary bg-transparent"
            >
              <div className="accordion-top relative h-11 lg:h-20 bg-beigeDark z-1">
                <span className="absolute block h-12 w-12 bg-secondary p-2">
                  <i className="block h-full w-full border border-solid border-primary p-1.5"></i>
                </span>
              </div>
              <div className="accordion-list lg:pb-12 pt-0 lg:pt-16">
                {data.toggle.map((node) => {
                  return (
                    <>
                      {node.title && (
                        <AccordionRow title={node.title}>
                          <div className="diamond flex"></div>
                          {node.text?.data?.text && (
                            <>
                              <div className="lg:pl-16 lg:pr-12">
                                <Markdown
                                  children={node.text.data.text}
                                  type="longText"
                                  className="accordion-text text-left font-display text-base leading-8 tracking-wider p-0 bg-transparent"
                                />
                              </div>
                              <div className="accordion-custom-button" />
                            </>
                          )}
                        </AccordionRow>
                      )}
                    </>
                  )
                })}
              </div>
            </Accordion>
          </div>
        )}
      </motion.div>
    </section>
  )
}

export default TogglesBlock
