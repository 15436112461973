import React from 'react'
import { motion } from 'framer-motion'
import { Heading } from '@bhvr/web-design-system'

const HeadingBlock = ({ data }) => {
  return (
    <section className="heading-section relative py-[4.4rem] lg:pb-20 lg:pt-32">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="relative z-10 box-border flex w-full flex-col items-center justify-center text-center text-white lg:container"
      >
        {data.title && data.title !== null && (
          <Heading
            use={data.title.tag}
            className="mb-12 text-goldDark lg:mb-20"
          >
            {data.title.text}
          </Heading>
        )}
      </motion.div>
      <div className="separator-dual">
        <div className="line" />
        <div className="diamond" />
        <div className="line" />
      </div>
    </section>
  )
}

export default HeadingBlock
