import React from 'react'
import { motion } from 'framer-motion'
import { Heading } from '@bhvr/web-design-system'

import Markdown from '@base/parts/markdown'
import Image from '@base/parts/image'

import NewsletterBackground from '../elements/newsletter-background'
import EarlyAccessForm from '../elements/early-access-form'

const FormBlock = ({ data, pageContext, globalData }) => {
  const theme = pageContext.theme.siteTheme.content.Newsletter
  const newsletterFormStyles = theme && theme.Form ? theme.Form : undefined
  let newsletterData = globalData?.earlyDemoData
  const ageGateData = globalData?.ageGateData
  const error = globalData?.stringsErrors
  const dates = globalData?.stringsDates

  newsletterData = {
    ...newsletterData,
    title: '',
    description: data?.customFormText?.data?.customFormText,
  }

  return (
    <section className="form-section relative pb-[4.4rem] lg:pb-32">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="relative z-10 box-border flex w-full flex-col items-center justify-center text-center text-black lg:container"
      >
        {data.title && data.title !== null && (
          <Heading
            use={data.title.tag}
            className="mb-4 text-xl lg:text-xxxl text-goldDark lg:mb-4"
          >
            {data.title.text}
          </Heading>
        )}
        {data.description && (
          <div className="mb-16 flex w-full flex-col items-center justify-center">
            <Markdown
              children={data.description.text?.data?.text}
              className="font-display text-base lg:text-lg"
            />
          </div>
        )}
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="relative z-10 box-border flex w-full flex-col items-center justify-center px-8 text-center text-black lg:px-0"
      >
        <div className="newsletter-content relative">
          <EarlyAccessForm
            pageContext={pageContext}
            strings={{ error, dates }}
            data={{ newsletterData, ageGateData }}
            styling={newsletterFormStyles}
            list={'islandsofinsight'}
            dataLayer={{
              ...pageContext.dataLayer.newsletter,
              eventAction: 'earlyAccess',
            }}
          />
        </div>
        <NewsletterBackground />
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="relative z-10 mt-12 box-border flex w-full flex-col items-center justify-center text-center text-black"
      >
        {globalData?.stringsLabels?.inPartnershipWith && (
          <h3 className="mb-6 font-nova text-base uppercase leading-none tracking-[0.8px]">
            {globalData?.stringsLabels?.inPartnershipWith}
          </h3>
        )}
        <a href="https://community.amd.com/t5/red-team/ct-p/red-team" target='_blank'>
          <Image
            media={data.partnerLogo}
            className="form-partner-logo h-auto w-48"
          />
        </a>
      </motion.div>
    </section>
  )
}

export default FormBlock
