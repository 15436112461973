import React from 'react'
import { motion } from 'framer-motion'
import SVG from 'react-inlinesvg'
import { Heading } from '@bhvr/web-design-system'
import { Link } from 'gatsby'

import { dateFormat } from '@base/utils/helpers/date-time'
import Button from '@base/parts/button'
import MixedMedia from '@base/parts/mixed-media'
import Image from '@base/parts/image'
import Markdown from '@base/parts/markdown'
import PromoTag from '@base/elements/store/promo-tag'

import arrow from '@img/icons/arrow.svg'

const HeroBlock = ({ data, pageContext, globalData }) => {
  let now = new Date()
  let currentDate = dateFormat(now)
  let reviews = data.reviews

  const listReviews = () => {
    return (
      <>
        {reviews.map((review, i) => (
          <Link
            to={review.link}
            target="_blank"
            rel="noopener noreferrer"
            className="flex w-1/3 flex-1 flex-col items-center text-white transition-all duration-300 hover:text-secondary md:w-auto"
            key={'reviewLink-' + i}
          >
            <div>
              <span className="sr-only">{review.mediaTitle}</span>
              <span className="text-center font-sans text-lg font-bold text-goldDark lg:text-[2.8rem]">
                {review.note}
              </span>
            </div>
            <div className="flex h-full items-center pt-2">
              <img
                className="max-h-[6rem] w-auto max-w-full"
                src={review.mediaLogo.url}
                alt={review.mediaTitle}
              />
            </div>
          </Link>
        ))}
      </>
    )
  }

  return (
    <section
      className={`${data.block?.blockClass ? data.block.blockClass : ''} hero hero-${pageContext.locale} relative mb-20 flex h-screen-2/3 min-h-[550px] flex-col items-center justify-between pb-0 md:mb-32 md:max-h-full lg:mb-0 lg:h-hero lg:max-h-[1200px] lg:min-h-[860px] lg:pb-60 xxxl:min-h-hero 
      ${pageContext.notificationBanner ? 'pt-32 md:pt-48' : ''}`}
    >
      {data.block?.anchorID && (
        <span
          id={data.block?.anchorID}
          className="hero-section-anchor absolute -top-80"
        />
      )}
      <div className="hero-content z-1 flex h-full flex-col items-center justify-center md:justify-start lg:justify-center px-12 pt-0 text-center text-blueGreyDark md:pt-24 lg:px-0 lg:pt-0">
        {data.logo && (
          <motion.div
            initial={{ opacity: 0, filter: 'blur(10px)' }}
            animate={{ opacity: 1, filter: 'blur(0px)' }}
            transition={{ duration: 0.5, delay: 1 }}
            className="mb-12 h-[7.8rem] md:mb-20 md:h-[13rem] xxl:h-[16rem] xxxl:mb-28 xxxl:h-[20rem] "
          >
            <Image
              media={data.logo}
              className="hero-logo h-full w-auto text-goldMedium"
            />
          </motion.div>
        )}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 1.5 }}
          className=""
        >
          {data.title && (
            <Heading
              use={data.title.tag}
              className="hero-title mb-20 text-base font-bold leading-[5rem] lg:text-xxl"
            >
              {data.title.text}
            </Heading>
          )}

          {data.subTitle && (
            <Markdown
              children={data.subTitle.text}
              className={`${data.subTitle.tag} hero-subTitle mb-8 text-md lg:mb-12 lg:text-xl`}
            />
          )}

          {data?.buttons?.map((button, i) => (
            <Button
              id={`btn-home-${i}`}
              link={button.link}
              trigger={button.trigger}
              style={button.style}
              key={`postBlockBtn-${button.id}`}
              size="lg"
              pageContext={pageContext}
              className="mb-12 lg:mb-20 xxxl:mb-28"
              gsEvent={
                button?.eventAction === 'wishlist'
                  ? 'wishlist_click'
                  : button?.eventAction === 'buy'
                    ? 'buy_click'
                    : ''
              }
              event={button.eventAction ? button.eventAction : ''}
              eventSection={
                button.eventElement ? button.eventElement : 'home keyart'
              }
              vendor={'steam store'}
            >
              <div className="btn-content">
                <i className="btn-decorator btn-decorator-left" />
                <span
                  className={globalData?.gameData?.promos?.discounts?.map(
                    (promo) =>
                      button.link.includes(promo.target) &&
                      currentDate >= dateFormat(promo.dateFrom) &&
                      currentDate < dateFormat(promo.dateTo) &&
                      'button-promo'
                  )}
                >
                  {globalData?.gameData?.promos?.discounts?.map(
                    (promo) =>
                      button.link.includes(promo.target) && (
                        <PromoTag
                          data={[
                            {
                              ...promo,
                              startDate: promo.dateFrom,
                              endDate: promo.dateTo,
                            },
                          ]}
                        />
                      )
                  )}
                  {button.label}
                  {button.icon && (
                    <SVG
                      onError={(error) => console.log(error.message)}
                      cacheRequests={true}
                      preProcessor={(code) =>
                        code.replace(/fill=".*?"/g, 'fill="currentColor"')
                      }
                      src={button.icon.localFile.publicURL}
                      className="ml-4 w-8 lg:w-10"
                    />
                  )}
                </span>
                <i className="btn-decorator btn-decorator-right" />
              </div>
            </Button>
          ))}
          {/* <div className="hero-stores flex flex-col justify-center">
            <Stores
              pageContext={pageContext}
              layout="icons"
              product="base-game"
              gameData={globalData.gameData}
              storeClassName="text-blueGreyDark"
              className="flex flex-wrap items-center justify-center space-x-8 sm:space-x-16"
            />
          </div> */}
        </motion.div>
      </div>
      {reviews && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 2 }}
          className="reviews w-full max-w-md lg:max-w-[40rem]"
        >
          <div className="reviews-inner relative z-10 flex justify-center space-x-8 md:space-x-16 lg:justify-start lg:space-x-20">
            {listReviews()}
          </div>
        </motion.div>
      )}
      {data.ctaText && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 2 }}
          className="hero-discover text-center"
        >
          <p className="mb-4 font-sans text-lg uppercase tracking-wider text-goldDark">
            {data.ctaText}
          </p>
          <SVG
            onError={(error) => console.log(error.message)}
            cacheRequests={true}
            preProcessor={(code) =>
              code.replace(/fill=".*?"/g, 'fill="currentColor"')
            }
            src={arrow}
            className=" h-12 w-auto text-goldDark"
          />
        </motion.div>
      )}

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.5 }}
        className={`absolute z-under h-full w-full bg-beige ${pageContext.notificationBanner ? 'top-0' : ''}`}
      >
        {data.media && (
          <MixedMedia
            media={data.media.media}
            poster={
              data.media.videoPoster && data.media.videoPoster.url
                ? data.media.videoPoster.url
                : ''
            }
            bgLoop
            autoplay
            loading="lazy"
            loop
            muted
            aboveFold
            pauseEnabled
            player="self"
            className={`hero-bg -mt-24 lg:-mt-32`}
          />
        )}
        {data.frontMedia && (
          <MixedMedia
            media={data.frontMedia}
            className="hero-front-media absolute"
          />
        )}
      </motion.div>
    </section>
  )
}

export default HeroBlock
