import React, { useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import SVG from 'react-inlinesvg'
import { Heading, Video } from '@bhvr/web-design-system'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Controller, EffectFade, Lazy } from 'swiper'
import 'swiper/css'
import 'swiper/css/effect-fade'

import Button from '@base/parts/button'
import Markdown from '@base/parts/markdown'

import PageBackground from '@elements/page-background'
import arrow from '@img/icons/arrow.svg'

const VideoBlock = ({ data, pageContext }) => {
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  const [refLoad, inViewLoad] = useInView({
    threshold: 0,
    triggerOnce: true,
  })

  const [refTitle, inViewTitle] = useInView({
    threshold: 0.5,
  })

  const [refDesc, inViewDesc] = useInView({
    threshold: 0.5,
  })

  // Play YT video and pause previous when changing slide
  const playYoutube = (current, previous) => {
    const previousVideo = document.querySelectorAll(`#yt-video-${previous - 1}`)
    const currentVideo = document.querySelectorAll(`#yt-video-${current}`)

    if (previousVideo) {
      previousVideo.forEach((video) => {
        video.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*')
      })
    }
    if (currentVideo) {
      currentVideo.forEach((video) => {
        video.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*')
      })
    }
  }

  return (
    <section className={`video-section relative pb-32 md:pb-60 lg:pb-96`}>
      {data.block?.anchorID && (
        <span
          id={data.block?.anchorID}
          className="video-section-anchor absolute top-16"
        />
      )}
      <div
        ref={refLoad}
        className="flex flex-col items-center justify-center text-center"
      >
        <div className="flex w-[65%] flex-col items-center justify-center text-center md:w-full">
          <div
            ref={refTitle}
            style={{
              opacity: inViewTitle ? 1 : 0,
              transition: 'all 0.5s',
            }}
            className="flex w-full flex-col items-center justify-center"
          >
            {data.title && (
              <Heading
                use={data.title.tag}
                className={`video-title text-lg text-goldDark md:text-xl lg:text-xxxl ${data.description ? 'mb-8' : 'mb-16'
                  }`}
              >
                <Markdown children={data.title.text} />
              </Heading>
            )}
          </div>
          {data.description && (
            <div
              ref={refDesc}
              style={{
                opacity: inViewDesc ? 1 : 0,
                transition: 'all 0.5s',
              }}
              className="mb-16 flex w-full flex-col items-center justify-center md:w-2/3 lg:w-1/2 xl:w-[40%]"
            >
              <Markdown
                children={data.description.text?.data?.text}
                className="font-display text-base font-[300] leading-tight lg:text-md"
              />
            </div>
          )}
        </div>
        <div className="video-player relative w-full lg:w-3/4 xxl:w-1200">
          <Swiper
            slidesPerView="1"
            modules={[Navigation, Pagination, Controller, EffectFade, Lazy]}
            pagination={{ clickable: true }}
            loop
            preloadImages={false}
            lazy={{ loadPrevNext: true }}
            allowTouchMove={true}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            onBeforeInit={(swiper) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current
              swiper.params.navigation.nextEl = navigationNextRef.current
            }}
            onSlideChange={(swiper) => {
              const { realIndex, previousIndex } = swiper
              playYoutube(realIndex, previousIndex)
            }}
          >
            {data?.videos?.map((video, i) => (
              <SwiperSlide key={`slider-slide-${i}`}>
                <Button
                  id={`videoBtn-${i}`}
                  link={video.youtubeEmbedID}
                  trigger={'videoModal'}
                  key={`videoBtn-${i}`}
                  pageContext={pageContext}
                  overwrite
                  className="video-button absolute left-0 top-0 h-full w-full"
                  ratingTimeBuffer={1}
                  trackable={true}
                  event={'watch_trailer'}
                  eventSection={'home videos'}
                >
                  <i className="video-play-icon"></i>
                </Button>
                <div className="video-embed bg-black">
                  {inViewLoad && (
                    <>
                      {/*                 
                        <Video
                          bgLoop
                          className="absolute top-0 h-full w-full"
                          player={video.videoType}
                          source={
                            video.videoType === 'youtube'
                              ? video.youtubeEmbedID
                                ? video.youtubeEmbedID
                                : ''
                              : video.video
                                ? video.video
                                : ''
                          }
                          poster={video.videoPoster ? video.videoPoster : ''}
                        /> 
                      */}
                      <iframe
                        id={`yt-video-${i}`}
                        src={`https://www.youtube.com/embed/${video.youtubeEmbedID}?enablejsapi=1&autoplay=${i === 0 ? 1 : 0}&loop=1&mute=1&controls=0&autohide=1&rel=0&loading=lazy&playsinline=1&start=3`}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                        className="absolute top-0 h-full w-full"
                      ></iframe>
                    </>
                  )}
                </div>
                {video.title && (
                  <h3 className="font-sans text-xs uppercase tracking-widest text-white lg:text-md">
                    {video.title}
                  </h3>
                )}
              </SwiperSlide>
            ))}
            <button
              className="absolute left-4 top-1/2 z-[2] -translate-y-1/2 rotate-90 cursor-pointer lg:left-6"
              aria-label="prev"
              ref={navigationPrevRef}
            >
              <SVG
                onError={(error) => console.log(error.message)}
                cacheRequests={true}
                preProcessor={(code) =>
                  code.replace(/fill=".*?"/g, 'fill="currentColor"')
                }
                src={arrow}
                className="h-10 w-auto text-white transition-all duration-300 lg:h-12 lg:hover:h-14"
              />
            </button>
            <button
              className="absolute right-4 top-1/2 z-[2] -translate-y-1/2 -rotate-90 cursor-pointer lg:right-6"
              aria-label="next"
              ref={navigationNextRef}
            >
              <SVG
                onError={(error) => console.log(error.message)}
                cacheRequests={true}
                preProcessor={(code) =>
                  code.replace(/fill=".*?"/g, 'fill="currentColor"')
                }
                src={arrow}
                className="h-10 w-auto text-white transition-all duration-300 lg:h-12 lg:hover:h-14"
              />
            </button>
          </Swiper>
        </div>
      </div>
      <PageBackground className="background-mask" />
    </section>
  )
}

export default VideoBlock
