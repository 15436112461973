import React, { useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Heading, Accordion, AccordionRow } from '@bhvr/web-design-system'

import useWindowSize from '@base/hooks/useWindowSize'
import Button from '@base/parts/button'
import Markdown from '@base/parts/markdown'
import MixedMedia from '@base/parts/mixed-media'

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

const Gallery = ({ data, pageContext }) => {
  const main = useRef()
  const windowSize = useWindowSize()

  useEffect(() => {
    if (windowSize.width > 991) {
      const ctx = gsap.context((self) => {
        // Init the Wrapper animations
        let wrapperTl = gsap.timeline({
          scrollTrigger: {
            trigger: '.gallery-slider-wrapper',
            start: 'top center-=200px',
            end: '+=100%',
            scrub: true,
          },
          onStart: function () {
            gsap.set('.gallery-slider-wrapper', { width: '100%' })
            gsap.set('.gallery-slider-thumbs-container', {
              width: '0px',
              borderWidth: '0',
              left: '0',
            })
            gsap.set('.gallery-slider-default-0', { opacity: '100%' })
          },
        })

        // Wrapper grow on scroll
        wrapperTl
          .to('.gallery-slider-wrapper', {
            width: '80%',
            duration: 12,
            ease: 'circ.out',
          })
          .to('.gallery-slider-thumbs-container', {
            width: '295px',
            borderWidth: '1px',
            duration: 5,
            stagger: 0.1,
          })
          .to('.gallery-slider-thumbs-container', {
            left: '-2rem',
            overflow: 'visible',
            duration: 2,
            stagger: 2,
          })
          .to(`.gallery-slider-default-0 .gallery-decorations-background`, {
            opacity: 0.85,
            duration: 2,
            stagger: 2,
            ease: 'circ.out',
          })

        // Slider panel
        gsap.utils.toArray('.gallery-slider-panel.reveal').forEach((box, i) => {
          let tl = gsap.timeline({
            scrollTrigger: {
              trigger: box,
              start: 'top top',
              pin: true,
              pinSpacing: false,
              scrub: true,
              markers: false,
              snap: {
                snapTo: [0.5],
                duration: { min: 0, max: 1 },
                delay: 0.1,
                ease: 'power1.inOut',
              },
            },
            onStart: function () {
              gsap.set(`.gallery-slider-text${i}`, {
                visibility: 'hidden',
                duration: 0,
              })
              gsap.set(
                `.gallery-slider-text${i} .gallery-slider-text-container`,
                { translateY: '15px', opacity: '0.3', duration: 0 }
              )
              gsap.set(`.gallery-slider-img${i} .gallery-slider-img`, {
                opacity: 0,
                duration: 0.1,
              })
            },
          })

          tl.fromTo(
            `.gallery-slider-img${i}`,
            { display: 'none' },
            { display: 'flex', duration: 0.1 }
          )
          tl.fromTo(
            `.gallery-slider-text${i}`,
            { visibility: 'hidden' },
            { visibility: 'visible', duration: 0.1 }
          )

          // Phase-in image content
          tl.add('start')
            .to(
              `.gallery-slider-default-${i - 1}`,
              { opacity: 0, duration: 2 },
              'start'
            ) // hide previous slide dummy image
            .to(
              `.gallery-slider-img${i} .gallery-slider-img`,
              { opacity: 1, duration: 1 },
              'start'
            ) // display current slide image

          // Phase-in text content
          tl.to(`.gallery-slider-default-${i}`, { opacity: 0, duration: 0.5 }) // hide current slide dummy image
            .to(`.gallery-slider-text${i} .gallery-slider-text-container`, {
              opacity: 1,
              translateY: '0',
              duration: 4,
            })

          // Stuck here for a bit
          tl.to({}, { duration: 6 }, 'middle').addLabel('middle')

          // Phase out content (if not last slide)
          if (
            i !==
            gsap.utils.toArray('.gallery-slider-panel.reveal').length - 1
          ) {
            tl.to(`.gallery-slider-text${i} .gallery-slider-text-container`, {
              opacity: 0,
              translateY: '-15px',
              duration: 4,
            })
            tl.add('end')
              .to(
                `.gallery-slider-default-${i}`,
                { opacity: 1, duration: 0 },
                'end'
              ) // display current slide dummy image
              .to(
                `.gallery-slider-img${i} .gallery-slider-img`,
                { opacity: 0, duration: 0 },
                'end'
              ) // hide current slide image
              .to(
                `.gallery-slider-text${i}`,
                { opacity: 0, duration: 1 },
                'end'
              )
          }
        })

        // Slider Nav
        let links = gsap.utils.toArray('.gallery-thumbs a')
        links.forEach((a) => {
          let element = document.querySelector(a.getAttribute('href'))
          let linkST = ScrollTrigger.create({
            trigger: element,
            start: 'top top',
          })

          ScrollTrigger.create({
            trigger: element,
            start: 'top top',
            end: 'bottom top',
            onToggle: (self) => self.isActive && setActive(a),
          })

          a.addEventListener('click', (e) => {
            e.preventDefault()
            let scrollPosition = linkST.end - 150
            gsap.to(window, {
              duration: 0.1,
              scrollTo: scrollPosition,
              overwrite: 'auto',
            })
          })
        })

        function setActive(link) {
          links.forEach((el) => el.classList.remove('active'))
          link.classList.add('active')
        }
        ScrollTrigger.refresh()
      }, main)
      return () => ctx.revert()
    }
  }, [])

  let galleryImages =
    data.contentGallery !== null ? data.contentGallery : data.images

  return (
    <section id="gallery" className="gallery-section relative">
      {data.title && (
        <Heading
          use={data.title.tag}
          className="gallery-title mx-auto text-center text-lg text-goldDark md:text-xl lg:text-xxxl"
        >
          {data.title.text}
        </Heading>
      )}

      {data.description && (
        <Markdown
          children={data.description.text?.data?.text}
          className="gallery-description mx-auto mt-16 max-w-[124rem] px-12 text-center font-display text-base leading-8 text-black lg:px-24 lg:text-lg lg:leading-10"
        />
      )}

      <div className="gallery-slider-background pointer-events-none">
        <div></div>
      </div>

      {/* MOBILE */}
      <div className="relative z-1 block lg:hidden">
        {data.contentGallery && (
          <Accordion
            defaultOpen="first"
            className="w-full border border-none border-goldDark bg-transparent pb-24 pt-12 lg:border-solid"
            single
          >
            {galleryImages.map((media, i) => {
              return (
                media.title && (
                  <AccordionRow title={media.title}>
                    {media.content && (
                      <>
                        <Markdown
                          children={media.content}
                          className="accordion-text mb-10 px-20 text-center font-display text-base leading-8 tracking-wider text-white"
                        />
                        <div className="accordion-image relative border border-solid border-primary p-2">
                          <MixedMedia
                            media={media.thumbnail}
                            className="z-1 border border-solid border-primary"
                          />
                          <div className="squares absolute left-0 top-0 h-full w-full">
                            <span className="absolute left-0 top-0 h-full w-full"></span>
                          </div>
                        </div>
                        <div className="accordion-custom-button" />
                      </>
                    )}
                  </AccordionRow>
                )
              )
            })}
          </Accordion>
        )}
      </div>

      {/* DESKTOP */}
      <div
        ref={main}
        className="gallery-slider-container relative hidden py-12 lg:flex lg:px-24 lg:pb-64 lg:pt-32"
      >
        {/* Slider Wrapper (Includes Nav, decorations and box style) */}
        <div className="gallery-slider-wrapper sticky-element">
          <div className="gallery-slider-thumbs-container absolute top-1/2 z-10 -translate-y-1/2 overflow-hidden">
            <nav className="gallery-thumbs w-[295px] py-4 pl-12 pr-8">
              {galleryImages?.map((media, i) => (
                <a
                  href={`#gallery-slider-panel-${i}`}
                  title={media.title}
                  className="gallery-thumbs-slide cursor-pointer px-4 py-2 text-white"
                >
                  <div className="gallery-thumbs-container relative flex items-center">
                    <i className="diamond"></i>
                    <Markdown
                      className="max-w-[22rem] pl-8 font-nova text-base font-bold uppercase leading-8 tracking-wide"
                      children={media.title}
                    />
                  </div>
                </a>
              ))}
            </nav>
          </div>
          <div className="gallery-decorations pointer-events-none">
            <div className="gallery-decorations-border">
              <i className="square absolute -left-4 -top-4">
                <i className="square-small absolute -bottom-1 -right-1"></i>
              </i>
              <i className="square absolute -right-4 -top-4">
                <i className="square-small absolute -bottom-1 -left-1"></i>
              </i>
              <i className="square absolute -bottom-4 -left-4">
                <i className="square-small absolute -right-1 -top-1"></i>
              </i>
              <i className="square absolute -bottom-4 -right-4">
                <i className="square-small absolute -left-1 -top-1"></i>
              </i>
            </div>
          </div>

          {galleryImages?.map((media, i) => (
            <div
              className={`gallery-slider-default gallery-slider-default-${i} opacity-0`}
            >
              <MixedMedia
                media={media.image}
                className={`gallery-slider-img h-full w-full`}
              />
              <div className="gallery-decorations-background">
                <div className="gallery-decorations-background-border pointer-events-none absolute border border-solid border-goldMedium">
                  <i className="absolute -left-2.5 -top-2.5 h-4 w-4 border border-solid border-goldMedium">
                    <i className="absolute left-2 top-2 h-2.5 w-2.5 border border-solid border-goldMedium"></i>
                  </i>
                  <i className="absolute -bottom-2.5 -left-2.5 h-4 w-4 border border-solid border-goldMedium">
                    <i className="absolute bottom-2 left-2 h-2.5 w-2.5 border border-solid border-goldMedium"></i>
                  </i>
                  <i className="absolute -bottom-2.5 -right-2.5 h-4 w-4 border border-solid border-goldMedium">
                    <i className="absolute bottom-2 right-2 h-2.5 w-2.5 border border-solid border-goldMedium"></i>
                  </i>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Slider Content*/}
        <div className="gallery-slider w-[80%]">
          {galleryImages?.map((media, i) => (
            <div
              className="gallery-slider-panel reveal"
              id={`gallery-slider-panel-${i}`}
            >
              <Button
                link={media.image}
                trigger="imageModal"
                pageContext={pageContext}
                className={`gallery-slider-button gallery-slider-img${i} z-1 cursor-pointer px-0`}
              >
                <MixedMedia
                  media={media.image}
                  className={`gallery-slider-img h-full w-full`}
                />
              </Button>
              <div
                className={`gallery-slider-text gallery-slider-text${i} flex items-center justify-center`}
              >
                <div className="gallery-decorations-background">
                  <div className="gallery-decorations-background-border pointer-events-none absolute border border-solid border-goldMedium">
                    <i className="absolute -left-2.5 -top-2.5 h-4 w-4 border border-solid border-goldMedium">
                      <i className="absolute left-2 top-2 h-2.5 w-2.5 border border-solid border-goldMedium"></i>
                    </i>
                    <i className="absolute -bottom-2.5 -left-2.5 h-4 w-4 border border-solid border-goldMedium">
                      <i className="absolute bottom-2 left-2 h-2.5 w-2.5 border border-solid border-goldMedium"></i>
                    </i>
                    <i className="absolute -bottom-2.5 -right-2.5 h-4 w-4 border border-solid border-goldMedium">
                      <i className="absolute bottom-2 right-2 h-2.5 w-2.5 border border-solid border-goldMedium"></i>
                    </i>
                  </div>
                </div>
                <div className="gallery-slider-text-container flex h-full w-full flex-col justify-center">
                  <Heading
                    use="h4"
                    className="gallery-slider-text-title mb-4 text-center"
                  >
                    {media.title}
                  </Heading>
                  <Markdown
                    className="text-center font-display text-[1.5rem] leading-7 text-secondary"
                    children={media.content}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Gallery
